import login from "./assets/login.png?as=webp"
import webphone from "./assets/webphone.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
import Countries from "../../../UI/Sections/Countries/Countries";
export default function Webphone(){
    const {t, i18n} = useTranslation('webphone');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={webphone}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('access.title')} showDots={false} linePos={"under"}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('access.text')}}/>
        </RegularSection>
        <ThirdSection src={login}>
            <div>
                <Paragraph title={t('presence.easy.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('presence.easy.text')}}/>
                </Paragraph>
                <Paragraph title={t('presence.maintenance.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('presence.maintenance.text')}}/>
                </Paragraph>
                <Paragraph title={t('presence.workspace.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('presence.workspace.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <RegularSection title={t('no-limit.title')} background={"var(--blue-azure)"}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('no-limit.text')}}/>
            <Countries wrapper={{padding: "2%"}}/>
        </RegularSection>

    </>)
}
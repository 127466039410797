import { useEffect, useState } from 'react';

export default function ChatwootWidget({customAttributes}) {
    const [chatReady, setChatReady] = useState(false)
    useEffect(() => {
        // Création du script
        const script = document.createElement('script');
        script.src =   `${process.env.REACT_APP_BASE_CHAT_URL}/packs/js/sdk.js`;
        script.async = true;
        script.defer = true;

        // Fonction à exécuter une fois le script chargé
        script.onload = () => {
            window.chatwootSDK.run({
                websiteToken: process.env.REACT_APP_CHAT_TOKEN,
                baseUrl: process.env.REACT_APP_BASE_CHAT_URL,
            });
        };

        // Ajout du script au document
        document.body.appendChild(script);

        // Nettoyage : suppression du script lorsque le composant est démonté
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(()=>{
        if(Object.keys(customAttributes).length > 0 && window.$chatwoot !== undefined && chatReady){
            window.$chatwoot.setCustomAttributes(customAttributes)
        }
        var i,elements = document.getElementsByClassName("branding--link")
        for (i = elements.length; i--;) {
            elements[i].parentNode.removeChild(elements[i]);
        }
    },[customAttributes, chatReady])

    window.addEventListener("chatwoot:ready", function () {
        setChatReady(true)
    });



    return null; // Ce composant n'affiche rien
}
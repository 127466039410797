import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate} from "react-router-dom";
import {appActions} from "../../store/app-data-slice";
import {Helmet, HelmetProvider} from "react-helmet-async";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React from "react";
import Breadcrumbs from "../Breadcrumbs";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function TitleHeader({title="", text="", textComponent=false, img="", showButton=true, children}){
    const {t, i18n} = useTranslation('common');
    const isMobile = useSelector(state => state.app.isMobile);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const breadcrumbs = useBreadcrumbs();
    let breadcrumb =
        <div className={"breadcrumb"}>
        {breadcrumbs.map(({ match,breadcrumb }) => <div><NavLink key={match.pathname} to={match.pathname}>
            {breadcrumb}
        </NavLink><NavigateNextIcon/></div>)}
        </div>
    return(
        <>
            <Helmet>
                <title>{`${title.replaceAll("\n"," ").replaceAll("<br>"," ")} - Cool Telecom`}</title>
                <meta
                    name="description"
                    content={text.replaceAll("\n"," ").replaceAll("<br>"," ")}
                />
            </Helmet>
            {!showButton?
                <div className={'headers'}>
                    <div className={'title'}>
                        <h1 style={{margin: "0"}}  dangerouslySetInnerHTML={{__html: title}}/>
                        {textComponent? <>{textComponent}</>:<span className={"text"} dangerouslySetInnerHTML={{__html: text}}/>}
                        <Breadcrumbs/>
                        {children}
                    </div>
                    <LazyLoadImage id="title-img" src={img} style={isMobile?{width:"50vw"}:{maxHeight: "59vh", borderRadius:"30px"}}/>
                    <div className={"gray-dots"} style={{
                        "height": "25%",
                        "width": "100px",
                        "position": "absolute",
                        "top": "52%",
                        "right": i18n.language !=="heb"?"11%":"",
                        "left": i18n.language !=="heb"?"":"8%",
                        "zIndex": "2"
                    }}/>
                </div>:<div className={'headers'}>
                    <div>
                        <div className={'title'} style={{
                            maxWidth: showButton && !isMobile? "85%":""
                        }}>
                            <h1 style={{margin: "0"}} dangerouslySetInnerHTML={{__html: title}}/>
                            <span className={"text"} dangerouslySetInnerHTML={{__html: text}}/>
                        </div>
                        {showButton && !isMobile &&
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                columnGap: "2%",
                                margin: "2% 4%"
                            }}>
                                <button className={"round-light"} onClick={()=>{
                                    navigate(`/${i18n.language}/requests/try`)
                                    dispatch(appActions.updateRoute("requests"))
                                    dispatch(appActions.updateSubRoute(""))
                                }}>{t('common:try-free')}</button>
                                <button className={"round-light"} onClick={()=>{
                                    navigate(`/${i18n.language}/requests/demo`)
                                    dispatch(appActions.updateRoute("requests"))
                                    dispatch(appActions.updateSubRoute(""))
                                }}>{t('common:ask-demo')}</button>
                            </div>}
                        <Breadcrumbs/></div>
                    <LazyLoadImage  src={img} style={isMobile?{width:"50vw"}:{maxHeight: "330px"}}/>
                    <div className={"gray-dots"} style={{
                        "height": "25%",
                        "width": "100px",
                        "position": "absolute",
                        "top": "52%",
                        "right": i18n.language ==="heb"?"":"11%",
                        "left": i18n.language ==="heb"?"8%":"",
                        "zIndex": "2"
                    }}/>
                </div>
            }

    {showButton && children}
            </>

    )
}
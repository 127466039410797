import {createSlice} from '@reduxjs/toolkit';

const appDataSlice = createSlice({
        name: 'app',
        initialState: {
            isMobile: false,
            isMobileMenuOpen: false,
            route: sessionStorage.getItem("route") || "home",
            subRoute: sessionStorage.getItem("subroutes") || "",
            language: sessionStorage.getItem("i18nextLng") || "",
            location: {},
        },
        reducers: {
            updateMobileStyle(state,action) {
                if(action.payload !== state.isMobile){
                    state.isMobile = action.payload;
                }
            },
            updateMobileMenuState(state,action) {
                state.isMobileMenuOpen = action.payload;
            },
            updateRoute(state,action) {
                sessionStorage.setItem("route", action.payload);
                state.route = action.payload;
            },
            updateSubRoute(state,action) {
                sessionStorage.setItem("subroutes", action.payload);
                state.subRoute = action.payload;
            },
            updateLocation(state,action) {
                state.location = action.payload;
            },
            updateLanguage(state,action) {
                let sessionLang = sessionStorage.getItem("i18nextLng")
                if(sessionLang !== action.payload && action.payload !== ""){
                    sessionStorage.setItem("i18nextLng", action.payload);
                }
                if(state.language !== action.payload && action.payload !== ""){
                    state.language = action.payload;
                }
            },
        }
    }
);

export const appActions = appDataSlice.actions;

export default appDataSlice;
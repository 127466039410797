import data from "./assets/data.png?as=webp"
import tablet from "./assets/tablet.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
import Countries from "../../../UI/Sections/Countries/Countries";
export default function VirtualNumbers(){
    const {t, i18n} = useTranslation('virtual-numbers');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={data}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('explanations.title')} showDots={false}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('explanations.text')}}/>
        </RegularSection>
        <ThirdSection src={tablet} title={t('advantages.title')}>
            <div>
                <Paragraph title={t('advantages.easy.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.easy.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.setup.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.setup.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.strenghten.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.strenghten.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.separate.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.separate.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <RegularSection title={t('use.title')} background={"var(--blue-azure)"}>
            <div>
                <Paragraph title={t('use.everyone.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('use.everyone.text')}}/>
                </Paragraph>
                <Paragraph title={t('use.prospection.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('use.prospection.text')}}/>
                </Paragraph>
                <Paragraph title={t('use.marketing.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('use.marketing.text')}}/>
                </Paragraph>
                <Paragraph title={t('use.virtual.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('use.virtual.text')}}/>
                </Paragraph>
            </div>
        </RegularSection>

    </>)
}
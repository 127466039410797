import world from "./assets/world.png?as=webp"
import woman from "./assets/woman.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
export default function ContactCenter(){
    const {t, i18n} = useTranslation('contact-center');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={world}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('explanations.title')} showDots={false}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('explanations.text')}}/>
        </RegularSection>
        <ThirdSection src={woman} title={t('advantages.title')}>
            <div>
                <Paragraph title={t('advantages.collaboration.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.collaboration.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.custom.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.custom.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.easy.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.easy.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
    </>)
}
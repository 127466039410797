import Checklist from "../../Checklist/Checklist";
import {useSelector} from "react-redux";
import Carousel from 'react-material-ui-carousel'
import { Paper} from '@mui/material'
import {useTranslation} from "react-i18next";
import "./Advantages.css";
export default function Advantages(){
    const {t, i18n} = useTranslation('ip-phone');
    const ColoredLine = ({ color="black" }) => (
        <div className={"colored-line"} style={{ borderTop: `2px solid ${color}`, marginLeft: 20, marginRight: 20, minWidth:"15%"}}/>
    );
    const isMobile = useSelector(state => state.app.isMobile);
    const tabs = (title, subs, background="white") =>{
        return <div style={{background:background,
            padding: background !== "white" && !isMobile?"40px 20px 60px":"20px",
        }}>
            <h4 style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                margin:"0px 20px",
                fontSize: background !== "white" && !isMobile?"var(--font-size-l)":"var(--font-size-s)"
            }}>
                {title}
            </h4>
            <div style={{padding: "25px 0px 10px"}}>
                <ColoredLine color={background !== "white"?"white":"var(--gray)"}/>
            </div>
            <span style={{
                fontSize: "11px",
                display: "flex",
                flexDirection: "column",
                rowGap: "15px"
            }}>
                {subs}
            </span>

        </div>
    }
    const adv = [{title:t('ip-phone:advantages.land-to-mobile.title'), body:<>
        <span style={{fontWeight:"900"}}>{t('ip-phone:advantages.land-to-mobile.subtitle')}</span>
            <Checklist array={[t('ip-phone:advantages.land-to-mobile.text')]}/>
            <span style={{}}>{t('ip-phone:advantages.land-to-mobile.subtext')}</span>
        </>},
        {title:t('ip-phone:advantages.ip.title'), body:<>
                <Checklist array={[t('ip-phone:advantages.ip.economic'),
                    t('ip-phone:advantages.ip.performance')
                ]}/>
                <span style={{paddingLeft:"25px"}}>{t('ip-phone:advantages.ip.subtext')}</span>
            </>},
        {title:t('ip-phone:advantages.fit.title'), body:<>
                <Checklist array={[t('ip-phone:advantages.fit.adapted'),
                    t('ip-phone:advantages.fit.callbacks'),
                    t('ip-phone:advantages.fit.remote-work'),
                    t('ip-phone:advantages.fit.confort'),
                    t('ip-phone:advantages.fit.greedy')
                ]}/>
            </>},
    ]
    return <div id={"advantages"}>
        <div className={"advantages-title-wrapper"} style={{
            padding:isMobile?"3% 5%":"1% 13%"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}><h3 style={{
                "margin": "0px",
                "width": "max-content"
            }}>{t('ip-phone:advantages.title')}</h3>{!isMobile?<ColoredLine color={"var(--green-crayola)"}/>:<></>}</div>
        </div>
        <div className={"elements"} style={{
            display: isMobile?"contents":"flex",
            alignItems: "flex-start"
        }}>
            {!isMobile?adv.map(p=>{
                    return tabs(p?.title, p?.body)
                }):
                <Carousel autoPlay={true} indicators={true} navButtonsAlwaysInvisible={true}>
                    {
                        adv.map(p=>{
                            return <Paper className={"carousel-tab"}>{tabs(p?.title, p?.body)}</Paper>
                        })
                    }
                </Carousel>
            }
        </div>
    </div>
}
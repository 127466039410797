import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {appActions} from "../../../store/app-data-slice";

export default function NotFound({subRoute=false, route=""}) {
    const dispatch = useDispatch();
    const selectedRoute = useSelector(state => state.app.route);
    const selectedSub = useSelector(state => state.app.subRoute);
    useEffect(()=>{
        console.log(selectedRoute)
        console.log(selectedSub)
        if(subRoute){
            dispatch(appActions.updateRoute(route))
            dispatch(appActions.updateSubRoute("not-found"))
            let base = document.getElementById("mainboard");
            base.classList.add("not-found")
        }
        else{
            dispatch(appActions.updateRoute(selectedRoute))
            dispatch(appActions.updateSubRoute("not-found"))
            let base = document.getElementById("mainboard");
            base.classList.add("not-found")
        }
    },[])
    return <><span>Page 404</span><span>Not Found</span></>;
}
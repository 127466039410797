import alaska from "./assets/alaska.png";
import hawai from "./assets/hawai.png"
import "./Countries.css";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getAuthClient} from "../../../utils/auth";

export default function Countries({wrapper={}}) {
    const {t, i18n} = useTranslation('unlimited');
    const list = ["Alaska", "DZ", "DE", "AD", "AR", "AU", "BG", "BR", "KH", "CA", "CL", "CY", "CO", "KR", "NL", "ES", "EE", "US", "FR",
        "GR", "GP", "GF", "GU", "Hawai", "HK", "HU","IN", "IE", "IS","IT","JP","MY","MQ","YT", "MX", "MC",
        "NO", "NZ", "PY", "PL", "PR", "PT", "PE", "RO", "RU", "CZ", "RE", "SG", "SK", "CH", "SE", "TW", "TH", "VE", "VN"]
    let [nameList, setNameList] = useState([])
    const auth = getAuthClient();
    useEffect(()=>{
        auth.setCRMData(`/siteCustom/countryNames/${i18n.language}`, {list},{showSuccess: false, showError:false}).then(res =>{
            setNameList(res)
        })
    },[i18n.language])
    return <>
        <div style={{
            ...wrapper,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        }}>
        <div className={"countries"}>
            {list.map(country => {
                let flag = `http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`
                let name = undefined;
                if(country.length > 2){
                    if(country === "Alaska"){
                        flag = alaska
                        name = t('alaska')
                    }
                    else if(country === "Hawai"){
                        flag = hawai
                        name = t('hawai')
                    }
                }
                return <span style={country === "Alaska"?{padding:"2%"}:{}}><img
                    alt={country}
                    src={flag}/>{country.length > 2 ? name : nameList[country]}</span>
            })}
        </div>

        </div>
    </>
}
import "./ThirdSection.css";
import i18n from "../../../i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {useEffect, useRef, useState} from "react";
import {useIsVisible} from "../../../utils/help";

export default function ThirdSection({src, title, id="", subTitle = undefined, reversed=false, children}) {
    const ref = useRef();
    const isVisible = useIsVisible(ref)
    const imageFormatting = (src) => {
        return <div className={"image-wrapper"} style={{position: "sticky"}}>
            <div className={"decorations disk"} style={{
                "height": "100px",
                "width": "100px",
                "background": "white",
                "borderRadius": "50px",
                "position": "absolute",
                "top": "-20px",
                "left": "-20px",
                "zIndex": "-1"
            }}/>
            <div className={"decorations circle"} style={{
                "height": "250px",
                "width": "250px",
                 border: "3px solid white",
                "borderRadius": "400px",
                "position": "absolute",
                "top": "250px",
                "left": "-80px",
                "zIndex": "0"
            }}/>
            <div className={"decorations dot"} style={{
                "height": "15px",
                "width": "15px",
                "background": "var(--green-crayola)",
                "borderRadius": "50px",
                "position": "absolute",
                "top": "288px",
                "left": "-55px",
                "zIndex": "0"
            }}/>
            <LazyLoadImage  src={src} style={{height: "400px", padding: "2% 5%"}}/>
        </div>
    };
    return <div className={`third-section ${reversed&&"reverse"} ${isVisible&&"slide"}`.trim()} ref={ref} id={id}>
        {imageFormatting(src)}
        <div className={"wrapper"}>
            <h3>{title}</h3>
             <div style={{
                 display: "flex",
                 flexDirection: "column",
                 rowGap: "20px",
             }}>

                 {subTitle!=undefined?<div style={{
                     display: "flex",
                     flexDirection: "row",
                     alignItems: "center"
                 }}>
                     <span className={"text"} style={{color:"var(--gray)"}}>{subTitle}</span>
                 </div>:<></>}
             </div>
            <div/>
            <div className={"decorations"} style={{
                "height": "-webkit-fill-available",
                "width": "50%",
                "background": "white",
                "borderRadius": "0 50px 0 50px",
                "position": "absolute",
                "top": "-20px",
                "right": "-20px",
                "zIndex": "-1"
            }}/>
            <div className={"gray-dots"} style={{
                "height": i18n.language === "heb"?"150px":"100px",
                "width": "300px",
                "position": "absolute",
                "top": "30px",
                "right": "-30px",
                "zIndex": "-1"
            }}/>
            {children}
        </div>
    </div>
}
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./Navbar.scss";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Index from "../LanguageSelection";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {appActions} from "../../store/app-data-slice";
import { HashLink as Link } from 'react-router-hash-link';
import PhoneIcon from "@mui/icons-material/Phone";
export default function Navbar({titles, collapsed, setCollapsed=()=>{}}){
    const [hovered, setHovered] = useState("")
    const navigate = useNavigate();
    const isMobile = useSelector(state => state.app.isMobile);
    const {t, i18n} = useTranslation('common');
    const userLocation = useSelector(state => state.app.location);
    const phones = {
        "+44":"+442035190975",
        "+33":"+33186477158",
        "+32":"+3228992884",
        "+972":"+972779909233"
    }
    useEffect(()=> {
        //console.log(hovered)
    },[hovered])
    const selectedRoute = useSelector(state => state.app.route);
    const selectedSub = useSelector(state => state.app.subRoute);
    const [windowWidth, setWindowSize] = useState(window.innerWidth);

    const handleWindowResize = useCallback(event => {
        setWindowSize(window.innerWidth);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);
    return(
        <div className={"navbar"} onClick={(event)=>{
            //console.log(event)
        }}>
            {isMobile || windowWidth < 1400?
                <div id="navbar-mobile-head" style={{
                    display: "flex",
                    width: "-webkit-fill-available",
                    justifyContent: "space-between",
                    alignItems: "center",}}>
                    <span className="text-link"><a href={"tel:"+(phones[userLocation?.country_calling_code]??"+442035190975")}>{phones[userLocation?.country_calling_code]??"+442035190975"}</a></span>
                <div className="langSelecWrapper">
                    <Index showText={false}/>
                </div>
                </div>:<></>
            }
            {Object.keys(titles).map(route => {
                let hover = false
                return <>{(("subRoutes" in titles[route] || "component" in titles[route]) || !isMobile) &&<div className={"nav-button" + (selectedRoute === route ? " active" : "")}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                    }} key={titles[route]}>
                        {!("subRoutes" in titles[route] || "component" in titles[route]) ?
                            <span><Link to={`/${i18n.language}#${route}`} onClick={() => {
                                //dispatch(appActions.updateRoute(`home`))
                                //dispatch(appActions.updateSubRoute(""))
                            }}>{titles[route]?.title}</Link></span> :
                            <span onClick={() => {
                                if (!("subRoutes" in titles[route])) {
                                    //dispatch(appActions.updateRoute(route))
                                    //dispatch(appActions.updateSubRoute(""))
                                    isMobile && setCollapsed(!collapsed)
                                }
                            }} onMouseOver={() => {
                                setHovered(route)
                            }}>
                        <Link to={`/${i18n.language}${(route??"")?.length>0?`/${route}`:""}`}
                              onClick={titles[route].subRoutes === undefined ? () => {
                                  //dispatch(appActions.updateSubRoute(""))
                              } : () => {
                              }}>{titles[route]?.title}</Link>
                    </span>}
                        {"subRoutes" in titles[route] ? <KeyboardArrowDownIcon
                            className={"menu-arrow" + (hovered === route ? " mobile-opened" : "")} onClick={() => {
                            if (hovered === route) {
                                setHovered("")
                            } else {
                                setHovered(route)
                            }
                        }
                        }/> : <></>}
                    </div>
                    <div className={"subroute-list"}>
                        <HorizontalRuleIcon className="sub-line" style={{transform: "rotate(90deg)"}}
                                            onMouseOver={() => {
                                                setHovered(route)
                                            }}/>
                        {"subRoutes" in titles[route] ?
                            <div className={"sub-routes " + (hovered === route ? " active" : "")} onMouseLeave={() => {
                                setHovered("")
                            }}>
                                {Object.keys(titles[route].subRoutes).map(sub_route => {
                                    let subs = titles[route].subRoutes
                                    return <a href={`/${i18n.language}/${route}/${sub_route}`} key={`/${route}/${sub_route}`}><span
                                        className={"sub-route" + (selectedSub === sub_route ? " active" : "")}
                                        onClick={() => {
                                            //dispatch(appActions.updateRoute(route))
                                            //dispatch(appActions.updateSubRoute(sub_route))
                                            isMobile && setCollapsed(!collapsed)
                                        }}>
                                    {!isMobile ? <HorizontalRuleIcon className="sub-line"
                                                                     style={{transform: "rotate(90deg)"}}/> : <></>}
                                        {subs[sub_route]?.title}
                                </span>
                                    </a>
                                })}
                            </div> : <></>}
                    </div>
                </div>
            }</>
            })}

            {isMobile || windowWidth < 1400? <div id={"mobile-button-wrapper"}>
                    <a href={`/${i18n.language}/requests/try`}><button className={"square-button"} onClick={()=>{
                    //dispatch(appActions.updateRoute("requests"))
                    //dispatch(appActions.updateSubRoute("try"))
                    //navigate(`/${i18n.language}/requests/try`)
                    setCollapsed(false)
                }}>{t('common:try-free')}</button></a>
                <a href={`/${i18n.language}/requests/demo`}><button className={"square-button"} onClick={()=>{
                    //dispatch(appActions.updateRoute("requests"))
                    //dispatch(appActions.updateSubRoute("demo"))
                    setCollapsed(false)
                    //navigate(`/${i18n.language}/requests/demo`)
                }}>{t('common:ask-demo')}</button></a>
            </div>:<></>}
        </div>
    )
}
import conf from "./assets/conf.png?as=webp"
import onconf from "./assets/onconf.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
export default function VideoConferencing(){
    const {t, i18n} = useTranslation('video-conferencing');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={conf}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <ThirdSection src={onconf} title={t('advantages.title')}>
            <div>
                <Paragraph title={t('advantages.anywhere.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.anywhere.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.interface.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.interface.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.admin.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.admin.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <RegularSection title={t('team.title')} background={"var(--blue-azure)"}>
            <div>
                <Paragraph title={t('team.pitch.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('team.pitch.text')}}/>
                </Paragraph>
                <Paragraph title={t('team.train.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('team.train.text')}}/>
                </Paragraph>
            </div>
        </RegularSection>

        <RegularSection title={t('subscription.title')}>
            <div>
                <Paragraph title={t('subscription.software.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('subscription.software.text')}}/>
                </Paragraph>
                <Paragraph title={t('subscription.data.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('subscription.data.text')}}/>
                </Paragraph>
            </div>
        </RegularSection>
    </>)
}
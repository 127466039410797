import "./Paragraph.css"
import {useSelector} from "react-redux";
export default function Paragraph({title=false, reduced=false, children}){
    const ColoredLine = ({ color="black" }) => (
        <div style={{ borderTop: `2px solid ${color}`, marginLeft: 10, marginRight: 10, minWidth:reduced?"25px":"50px"}}/>
    );
    const isMobile = useSelector(state => state.app.isMobile);
    return <>
        {title?
    <div className={"paragraph"} style={{
        display: "flex",
        flexDirection: reduced?"column":"row",
        alignItems: reduced?"flex-start":"center"
    }}>
        <h4 style={{whiteSpace: isMobile?"":"nowrap",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "0"
        }}>{isMobile?<></>:<ColoredLine color={"var(--green-crayola)"}/>}{title}</h4>
        <span style={{color:"var(--gray)"}}>{children}</span>
    </div>:<span className={"paragraph"} style={{color:"var(--gray)"}}>{children}</span>}
        </>
}
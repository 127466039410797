import {BrowserRouter, Route, Routes} from "react-router-dom";
import { Component, React } from "react";
import Mainboard from "./Mainboard";
import {useTranslation} from "react-i18next";
import {Navigate} from "react-router";
import NotFound from "./Pages/NotFound";
import Header from "./Header/Header";

export default function AppRouter(){
        const {t, i18n} = useTranslation();
        let languages = i18n.services.languageUtils.options.whitelist;
        return (
            <BrowserRouter>
                <Routes>
                    {languages.map(lang =>{
                        return <Route exact key={`/${lang}/*`} path={`/${lang}/*`} element={<Mainboard lang={lang}/>}/>
                    })}
                    <Route exact key={`/`} path={`/`} element={<Mainboard lang={"fr"}/>}/>
                    <Route path="*" element={<Mainboard lang={"fr"} notFound={true}/>} />
                </Routes>
            </BrowserRouter>);
}

import {useState} from "react";
import "./Collapsable.css";
import AddIcon from '@mui/icons-material/Add';
import {useSelector} from "react-redux";
import useCollapse from "react-collapsed";
export default function Collapsable({title, text, active=true, children=undefined}){
    const [collapsed, setCollapsed] = useState(active?true:false)
    const config = {
        defaultExpanded: false,
        collapsedHeight: 0
    };
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
    const ColoredLineTitle = ({ color="var(--green-crayola)",isMobile }) => (
        !isMobile?<div style={{ borderTop: `2px solid ${color}`, margin: "0 5px", minWidth:"50px"}}/>: <></>
    );
    const ColoredLine = ({ color="var(--blue-sea)" }) => (
        <div style={{ borderTop: `2px solid ${color}`, minWidth:"100%"}}/>
    );
    const isMobile = useSelector(state => state.app.isMobile);
        return(
            <div className={"collapsable-section" + (!collapsed?" open":"")}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }} {...getToggleProps({onClick:()=>{
                    if(active){
                        setCollapsed(!collapsed)
                    }
                    }})}><span style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    color: children !== undefined? "var(--green-crayola)": "black"
                }}><ColoredLineTitle isMobile={isMobile}/>{title}</span> {active?<AddIcon
                    style={{fill: "var(--green-crayola)", cursor: "pointer"}}/>:<></>}</div>
                <div {...getCollapseProps()}>
                {children !== undefined?<div className={"content"}>{children}</div>:
                    <span className={"text"} dangerouslySetInnerHTML={{__html: text}}/>
                }
                </div>
                <ColoredLine/>
            </div>)
}